import React from "react";
import {
  Box,
  Typography,
  Slider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Drawer,
} from "@mui/material";

const FilterBar3 = ({
  plantnameFilter,
  setPlantnameFilter,
  cbdFilter,
  setCbdFilter,
  typeFilter,
  setTypeFilter,
  effectFilter,
  setEffectFilter,
  tasteFilter,
  setTasteFilter,
  thcRange,
  setThcRange,
  useFilter,
  applyFilter,
  resetFilter,
  setFilterDrawerOpen,
  filterDrawerOpen,
  minThc,
  maxThc,
  marks,
  originalPlants,
}) => {
  return (
    <Drawer
      anchor="left"
      open={filterDrawerOpen}
      onClose={() => setFilterDrawerOpen(false)}
    >
      <Box
        sx={{
          padding: 2,
          borderRight: 1,
          borderColor: "divider",
          height: "100%",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Filter
        </Typography>

        {/* Filter für Pflanzensorte */}
        <TextField
          label="Pflanzensorte"
          variant="outlined"
          fullWidth
          value={plantnameFilter}
          onChange={(e) => setPlantnameFilter(e.target.value)}
        />

        {/* Filter für THC-Gehalt mit Slider */}
        <FormControl fullWidth sx={{ marginTop: 5 }}>
          <InputLabel id="thc-range-label" sx={{ marginTop: -5 }}>
            THC-Gehalt
          </InputLabel>
          <Slider
            value={thcRange}
            onChange={(_, newValue) => setThcRange(newValue)}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${value}%`}
            min={parseFloat(minThc)}
            max={parseFloat(maxThc)}
            marks={marks}
            step={1}
            sx={{ width: "85%", margin: "auto", marginBottom: 3 }}
            color="success"
          />
        </FormControl>

        {/* Filter für CBD-Gehalt mit Dropdown */}
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel id="cbd-filter-label">CBD-Gehalt</InputLabel>
          <Select
            label="CBD-Gehalt"
            labelId="cbd-filter-label"
            id="cbd-filter"
            value={cbdFilter}
            onChange={(e) => setCbdFilter(e.target.value)}
          >
            <MenuItem value="">Alle</MenuItem>
            <MenuItem value="Hoch">Hoch</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="Niedrig">Niedrig</MenuItem>
          </Select>
        </FormControl>

        {/* Filter für Type mit Dropdown */}
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel id="type-filter-label">Typ</InputLabel>
          <Select
            label="Typ"
            labelId="type-filter-label"
            id="type-filter"
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value)}
          >
            <MenuItem value="">Alle</MenuItem>
            <MenuItem value="Sativa">Sativa</MenuItem>
            <MenuItem value="Indica">Indica</MenuItem>
            <MenuItem value="Hybrid">Hybrid</MenuItem>
          </Select>
        </FormControl>

        {/* Filter für Wirkung mit Dropdown */}
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel id="effect-filter-label">Wirkung</InputLabel>
          <Select
            label="Wirkung"
            labelId="effect-filter-label"
            id="effect-filter"
            value={effectFilter}
            onChange={(e) => setEffectFilter(e.target.value)}
          >
            <MenuItem value="">Alle</MenuItem>
            <MenuItem value="Körperlich entspannend">
              Körperlich entspannend
            </MenuItem>
            <MenuItem value="Schwer">Schwer</MenuItem>
            <MenuItem value="Stoned">Stoned</MenuItem>
            <MenuItem value="Psychedelisch">Psychedelisch</MenuItem>
            <MenuItem value="Ausgeglichen">Ausgeglichen</MenuItem>
            <MenuItem value="Klar">Klar</MenuItem>
            <MenuItem value="Beruhigend">Beruhigend</MenuItem>
            <MenuItem value="Euphorisch">Euphorisch</MenuItem>
            <MenuItem value="Aufmunterned">Aufmunterned</MenuItem>
            <MenuItem value="Lachen">Lachen</MenuItem>
            <MenuItem value="Motivierend">Motivierend</MenuItem>
            <MenuItem value="Schnelles Denken">Schnelles Denken</MenuItem>
            <MenuItem value="Kreativ">Kreativ</MenuItem>
            <MenuItem value="Fokus">Fokus</MenuItem>
            <MenuItem value="Schläfrig">Schläfrig</MenuItem>
            <MenuItem value="Zelebral">Zelebral</MenuItem>
            {/* Hier könntest du die einzigartigen Wirkungen aus der aktuellen Tabelle extrahieren */}
            {/* Beispiel: currentPlants.flatMap((plant) => plant.content.Effect).filter((value, index, self) => self.indexOf(value) === index).map((value) => <MenuItem key={value} value={value}>{value}</MenuItem>) */}
          </Select>
        </FormControl>

        {/* Filter für Geschmack mit Dropdown */}
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel id="taste-filter-label">Geschmack</InputLabel>
          <Select
            label="Geschmack"
            labelId="taste-filter-label"
            id="taste-filter"
            value={tasteFilter}
            onChange={(e) => setTasteFilter(e.target.value)}
          >
            <MenuItem value="">Alle</MenuItem>
            <MenuItem value="Durchdringend">Durchdringend</MenuItem>
            <MenuItem value="Erdig">Erdig</MenuItem>
            <MenuItem value="Fruchtig">Fruchtig</MenuItem>
            <MenuItem value="Skunk">Skunk</MenuItem>
            <MenuItem value="Süßigkeit">Süßigkeit</MenuItem>
            <MenuItem value="Käse">Käse</MenuItem>
            <MenuItem value="Pfeffer">Pfeffer</MenuItem>
            <MenuItem value="Kiefer">Kiefer</MenuItem>
            <MenuItem value="Minze">Minze</MenuItem>
            <MenuItem value="Zitrusartig">Zitrusartig</MenuItem>
            <MenuItem value="Heidelbeere">Heidelbeere</MenuItem>
            <MenuItem value="Diesel">Diesel</MenuItem>
            <MenuItem value="Sauer">Sauer</MenuItem>
            <MenuItem value="Schokolade">Schokolade</MenuItem>
            <MenuItem value="Ananas">Ananas</MenuItem>
            <MenuItem value="Banane">Banane</MenuItem>
            <MenuItem value="Vanille">Vanille</MenuItem>
            <MenuItem value="Cookie">Cookie</MenuItem>
            <MenuItem value="Wassermelone">Wassermelone</MenuItem>
            {/* Hier könntest du die einzigartigen Geschmacksrichtungen aus der aktuellen Tabelle extrahieren */}
            {/* Beispiel: currentPlants.flatMap((plant) => plant.content.Taste).filter((value, index, self) => self.indexOf(value) === index).map((value) => <MenuItem key={value} value={value}>{value}</MenuItem>) */}
          </Select>
        </FormControl>
        <Button
          onClick={() => {
            applyFilter(originalPlants);
            setFilterDrawerOpen(false);
          }}
          variant="contained"
          color="success"
          sx={{
            margin: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          Filter anwenden
        </Button>
        <Button
          onClick={resetFilter}
          variant="contained"
          color="success"
          sx={{
            margin: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          Filter zurücksetzen
        </Button>
      </Box>
    </Drawer>
  );
};

export default FilterBar3;

import * as React from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import supabase from "../../../config/supabaseClient.js";
import { useState} from "react";

export default function CreateMemberMessageDialog() {
  const [message, setMessage] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClickOpen = () => {
    //console.log("Dialog wird geöffnet");
    setDialogOpen(true);
    //console.log(dialogOpen);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCreateMessage = async () => {
    try {
      // Überprüfen, ob die Nachricht nicht leer ist
      if (!message) {
        console.error("Die Nachricht darf nicht leer sein.");
        return;
      }

      // Hole den höchsten Wert der id-Spalte in der Tabelle misc
      const { data: maxIdData, error: maxIdError } = await supabase
        .from("misc")
        .select("id", { order: { by: "id", descending: true }, limit: 1 });

      if (maxIdError) {
        console.error(
          "Fehler beim Abrufen des höchsten ID-Werts:",
          maxIdError.message
        );
        return;
      }

      // Ermittle den nächsten Wert für die id
      const maxIdDataSorted = maxIdData.sort((a, b) => b.id - a.id);

      const nextId =
        maxIdDataSorted && maxIdDataSorted.length > 0
          ? maxIdDataSorted[0].id + 1
          : 1;

      // Eintrag für die Tabelle "News" erstellen
      await supabase
        .from("misc")
        .insert([
          { id: nextId, keyword: "News", content: { message: message } },
        ])
        .then(({ data, error }) => {
          //console.log("Data:", data ? data[0] : null);
          //console.log("Error:", error ? error.message : null);
          //console.log(message)

          if (error) {
            console.error("Fehler beim Erstellen der Nachricht:", error);
            return;
          }

          // Erfolgreich erstellte Nachricht
          //console.log("Nachricht erfolgreich erstellt:", data ? data[0] : null);

          // Optional: Setze den React Hook setMessage, um die Nachricht zurückzusetzen
          setMessage("");
          handleCloseDialog();
        });
    } catch (error) {
      console.error("Allgemeiner Fehler:", error);
    }
  };

  return (
    <React.Fragment>
      <Button
      color="success"
        className="button"
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          "@media screen and (max-width: 600px)": {
            // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
            fontSize: 10,
            // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
          },
        }}
      >
        Mitteilung erstellen
      </Button>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle> Vereinsmitteilung:</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              multiline
              rows={3}
              placeholder="Gib hier deine Nachricht ein."
              onChange={(e) => {
                setMessage(e.target.value);
                /* console.log("Message State:", message) */
              }}
              style={{ width: "400px" }}
            ></TextField>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Schließen</Button>
          <Button onClick={handleCreateMessage}>Erstellen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

//Basic
import { React, useEffect, useState } from "react";
import { Text } from "react-native";
import "../../App.css";
import supabase from "../../config/supabaseClient.js";
import { styles } from "../../styles.js";
import { Helmet, HelmetProvider } from "react-helmet-async";

//comps
import FilterBar3 from "./FilterBar.js";
import SortableTableHeaderCell from "./SortableTableHeaderCell.js";

//MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

// MUI Components for Filter Bar
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import { margin } from "@mui/system";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--primary)",
    color: theme.palette.common,
    border: "1px solid black",
    "@media screen and (max-width: 900px)": {
      // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
      fontSize: 10, // Beispiel: Verkleinern Sie die Schriftgröße
      padding: "6px",
      minWidth: "15px",
      maxWidth: "120px",
      // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    "@media screen and (max-width: 900px)": {
      // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
      fontSize: 10, // Beispiel: Verkleinern Sie die Schriftgröße
      padding: "6px",
      minWidth: "15px",
      maxWidth: "120px",
      // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
    },
  },
  // Hinzufügen von Padding für das Eingabefeld
  position: "relative",
  padding: "8px",
  border: "1px solid black",
  "@media screen and (max-width: 900px)": {
    // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
    // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: "1px solid black",
  },
}));

const PlantsData3 = () => {
  const [filteredPlants, setFilteredPlants] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  // Filter-Zustände
  const [plantnameFilter, setPlantnameFilter] = useState("");
  const [cbdFilter, setCbdFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [effectFilter, setEffectFilter] = useState("");
  const [tasteFilter, setTasteFilter] = useState("");
  const [minThc, setMinThc] = useState(0);
  const [maxThc, setMaxThc] = useState(30);
  const [thcRange, setThcRange] = useState([0, 30]);

  const isScreenSmall = useMediaQuery("(max-width:1110px)");
  const minWindoSize = 500;

  // Originaldaten aus der Datenbank
  const [originalPlants, setOriginalPlants] = useState([]);

  const handleFilterDrawerOpen = () => {
    setFilterDrawerOpen(true);
    //console.log(filterDrawerOpen)
  };

  /*   useEffect(() => {
    // Laden der Daten aus der Datenbank
    const fetchMiscData = async () => {
      try {
        const { data, error } = await supabase
          .from("misc")
          .select("content")
          .eq("keyword", "Plant");

        if (error) {
          console.error("Fehler beim Datenabruf:", error.message);
          return;
        }

        const plantsData = data;

        // Setze die Anfangswerte von thcRange basierend auf den vorhandenen Daten
        const thcValues = plantsData.map((plant) =>
          parseFloat(plant.content.THC)
        );
        const newMinThc = Math.min(...thcValues);
        const newMaxThc = Math.max(...thcValues);
        setMinThc(newMinThc);
        setMaxThc(newMaxThc);
        setThcRange([newMinThc, newMaxThc]);

        setOriginalPlants(plantsData);
        // Anwenden der Filterlogik
        //applyFilter(plantsData);
      } catch (error) {
        console.error(
          "Ein unerwarteter Fehler ist aufgetreten:",
          error.message
        );
      }
    };

    // Rufe die Funktion zum Laden der Daten auf
    fetchMiscData();
  }, []); */

  useEffect(() => {
    // Laden der Daten aus der Datenbank
    const fetchPlantData = async () => {
      try {
        const { data, error } = await supabase
          .from("misc")
          .select("content")
          .eq("keyword", "Plants Data")
          .single(); // Annahme, dass es nur eine Zeile mit diesem Keyword gibt

        if (error) {
          console.error("Fehler beim Datenabruf:", error.message);
          return;
        }
        //console.log(data);

        // Prüfen, ob die Daten im 'content' Feld bereits als Objekt vorliegen
        if (data && data.content) {
          let plantsData;
          if (typeof data.content === "string") {
            plantsData = JSON.parse(data.content);
          } else {
            plantsData = data.content;
          }
          //console.log("plantsData", plantsData)
          setOriginalPlants(plantsData);
          applyFilter(plantsData);
          //console.log("originalPlants", originalPlants)
        }
      } catch (error) {
        console.error(
          "Ein unerwarteter Fehler ist aufgetreten:",
          error.message
        );
      }
    };

    // Rufe die Funktion zum Laden der Daten auf
    fetchPlantData();
  }, []);

  // Überwache Änderungen an den Filterwerten und wende die Filterlogik an
  useEffect(() => {
    applyFilter(originalPlants);
  }, [
    plantnameFilter,
    cbdFilter,
    typeFilter,
    effectFilter,
    tasteFilter,
    minThc,
    maxThc,
  ]);

  // Direkte Verwendung der Filterlogik
  /* const applyFilter = (data) => {
    if (!Array.isArray(data)) {
      console.error("Ungültiger Daten-Typ:", typeof data);
      console.log(data);
      return;
    }

    const newFilteredPlants = data.filter((plant) => {
      const plantTHC = parseFloat(plant.content.THC);

      const isPlantIncluded =
        (plant.content.plantname
          .toLowerCase()
          .includes(plantnameFilter.toLowerCase()) ||
          !plantnameFilter) &&
        (!thcRange[0] || plantTHC >= thcRange[0]) &&
        (!thcRange[1] || plantTHC <= thcRange[1]) &&
        (plant.content.CBD === cbdFilter || !cbdFilter) &&
        (plant.content.type === typeFilter || !typeFilter) &&
        (!effectFilter ||
          (plant.content.Effect &&
            plant.content.Effect.some((effect) =>
              effect.includes(effectFilter)
            )) ||
          !effectFilter) &&
        (!tasteFilter ||
          (plant.content.Taste &&
            plant.content.Taste.some((taste) => taste.includes(tasteFilter))) ||
          !tasteFilter);

      return isPlantIncluded;
    });

    setFilteredPlants(newFilteredPlants);

    //console.log("Filtered Plants:",newFilteredPlants);
    //console.log("Original Plants:",originalPlants);
  }; */

  const applyFilter = (data) => {
    const dataArray = Object.entries(data).map(([key, value]) => ({
      plantname: key, // Der Pflanzenname
      ...value, // Die restlichen Daten der Pflanze
    }));

    //console.log("dataArray", dataArray);
    setOriginalPlants(dataArray);
    const newFilteredPlants = dataArray.filter((plant) => {
      const plantTHC = parseFloat(plant.THC); // Zugriff direkt auf das THC

      const isPlantIncluded =
        (!plantnameFilter ||
          plant.plantname
            .toLowerCase()
            .includes(plantnameFilter.toLowerCase())) &&
        (!thcRange[0] || plantTHC >= thcRange[0]) &&
        (!thcRange[1] || plantTHC <= thcRange[1]) &&
        (!cbdFilter || plant.CBD === cbdFilter) &&
        (!typeFilter || plant.type === typeFilter) &&
        (!effectFilter ||
          (plant.Effect &&
            plant.Effect.some((effect) =>
              effect.toLowerCase().includes(effectFilter.toLowerCase())
            ))) &&
        (!tasteFilter ||
          (plant.Taste &&
            plant.Taste.some((taste) =>
              taste.toLowerCase().includes(tasteFilter.toLowerCase())
            )));

      return isPlantIncluded;
    });

    //console.log("newFilteredPlants", newFilteredPlants);
    setFilteredPlants(newFilteredPlants);
  };

  const marks = [
    { value: minThc, label: `${minThc}%` },
    { value: maxThc, label: `${maxThc}%` },
  ];

  /*  const handleSort = (key) => {
    const sortedPlants = [...filteredPlants];
    const direction =
      sortConfig.key === key && sortConfig.direction === "ascending"
        ? "descending"
        : "ascending";
    setSortConfig({ key, direction });

    sortedPlants.sort((a, b) => {
      const valueA = a.content[key];
      const valueB = b.content[key];

      if (key === "BloomDuration") {
        // Extrahiere die Zahlen aus der Zeichenkette und vergleiche sie als Ganzes
        const numberA = parseInt(valueA.match(/\d+/)[0]);
        const numberB = parseInt(valueB.match(/\d+/)[0]);
        return direction === "ascending"
          ? numberA - numberB
          : numberB - numberA;
      } else if (key === "THC") {
        // Vergleiche als ganze Zahl
        const numberA = parseFloat(valueA);
        const numberB = parseFloat(valueB);
        return direction === "ascending"
          ? numberA - numberB
          : numberB - numberA;
      } else if (typeof valueA === "string" && typeof valueB === "string") {
        return direction === "ascending"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return direction === "ascending" ? valueA - valueB : valueB - valueA;
      }
    });

    setFilteredPlants(sortedPlants);
  }; */

  const handleSort = (key) => {
    const sortedPlants = [...filteredPlants];
    const direction =
      sortConfig.key === key && sortConfig.direction === "ascending"
        ? "descending"
        : "ascending";
    setSortConfig({ key, direction });

    sortedPlants.sort((a, b) => {
      const valueA = a[key];
      const valueB = b[key];

      if (key === "BloomDuration") {
        // Extrahiere die Zahlen aus der Zeichenkette und vergleiche sie als Ganzes
        const numberA = parseInt(valueA.match(/\d+/)[0]);
        const numberB = parseInt(valueB.match(/\d+/)[0]);
        return direction === "ascending"
          ? numberA - numberB
          : numberB - numberA;
      } else if (key === "THC") {
        // Vergleiche als ganze Zahl
        const numberA = parseFloat(valueA);
        const numberB = parseFloat(valueB);
        return direction === "ascending"
          ? numberA - numberB
          : numberB - numberA;
      } else if (typeof valueA === "string" && typeof valueB === "string") {
        return direction === "ascending"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return direction === "ascending" ? valueA - valueB : valueB - valueA;
      }
    });
    //console.log(sortedPlants)
    setFilteredPlants(sortedPlants);
  };

  // Funktion zum Zurücksetzen der Filter
  const resetFilter = () => {
    setPlantnameFilter("");
    setThcRange([0, 30]); // Beispielwerte, anpassen nach Bedarf
    setCbdFilter("");
    setTypeFilter("");
    setEffectFilter("");
    setTasteFilter("");
    setFilteredPlants(originalPlants); // originalPlants ist der unveränderte Datensatz
    setFilterDrawerOpen(false);
  };

  //console.log(minThc, maxThc)
  const formatPlantNames = (plants) => {
    return plants.map((plant) => ({
      ...plant,
      plantname: plant.plantname.replace(/\s+/g, "-"),
    }));
  };

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Anmeldung der Anbauvereinigung Hemp Heaven Hannover</title>
          <meta
            name="description"
            content="Wenn du die Registrierung der Anbauvereinigung Hemp Heaven Hannover abgeschlossen hast kannst du dich hier mit deinen Anmeldedaten anmelden um noch mehr Inhalte zu sehen."
          />
          <meta
            name="keywords"
            content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
          />
        </Helmet>
        <FilterBar3
          plantnameFilter={plantnameFilter}
          setPlantnameFilter={setPlantnameFilter}
          cbdFilter={cbdFilter}
          setCbdFilter={setCbdFilter}
          typeFilter={typeFilter}
          setTypeFilter={setTypeFilter}
          effectFilter={effectFilter}
          setEffectFilter={setEffectFilter}
          tasteFilter={tasteFilter}
          setTasteFilter={setTasteFilter}
          thcRange={thcRange}
          setThcRange={setThcRange}
          applyFilter={applyFilter}
          //useFilter={useFilter}
          resetFilter={resetFilter}
          setFilterDrawerOpen={setFilterDrawerOpen}
          filterDrawerOpen={filterDrawerOpen}
          minThc={minThc}
          maxThc={maxThc}
          marks={marks}
          originalPlants={originalPlants}
        />
        <Box
          component={Paper}
          sx={{
            width: "90%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            margin: "auto",
            marginTop: 5,
          }}
        >
          <h1
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Cannabissorten-Tabelle
          </h1>
          <p style={{ ...styles.normalText, width: "95%" }}>
            In dieser Tabelle findet ihr alle Sorten, die wir anbieten können.
            In regelmäßigen Abständen könnt ihr für eure Wunschsorte abstimmen.
            In jeder Abstimmungsrunde wird je die meist gewünschte Indica-,
            Sativa- und Hybridsorte ausgewählt und für den nächsten Zyklus
            angebaut. Wurde eine Sorte angebaut wird sie für die nächsten
            Abstimmungen nicht in Betracht gezogen, damit alle Mitglieder sich
            durch die meisten Sorten probieren können und jeder die Chance
            erhält seine Wunschsorte zu probieren. Um die Tabelle zu sortieren
            klicke auf den entsprechenden Tabellenkopf der Spalte oder nutze den
            Filter, indem du auf das Filter-Symbol links über der Tabelle
            klickst.
          </p>
          <IconButton
            onClick={() => handleFilterDrawerOpen()}
            style={{ color: "black", marginRight: "auto", marginTop: 10 }} // Hier wird marginLeft hinzugefügt
          >
            <FilterListIcon />
          </IconButton>

          <TableContainer
            component={Paper}
            sx={{
              width: "95%",
              maxWidth: "95%", // Begrenze die maximale Breite auf 100% der Bildschirmbreite
              overflowX: "auto",
              display: "block",
              marginBottom: 4,
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {/* Bild Spalte */}
                  <StyledTableCell>Bild</StyledTableCell>

                  {/* Pflanzensorte Spalte */}
                  <SortableTableHeaderCell
                    label="Pflanzensorte"
                    field="plantname"
                    sortConfig={sortConfig}
                    handleSort={handleSort}
                  />
                  <SortableTableHeaderCell
                    label="THC-Gehalt"
                    field="THC"
                    sortConfig={sortConfig}
                    handleSort={handleSort}
                  />
                  <SortableTableHeaderCell
                    label="CBD-Gehalt"
                    field="CBD"
                    sortConfig={sortConfig}
                    handleSort={handleSort}
                  />
                  <SortableTableHeaderCell
                    label="Typ"
                    field="type"
                    sortConfig={sortConfig}
                    handleSort={handleSort}
                  />

                  <StyledTableCell align="left" className="plantstablewidth">
                    Wirkung
                  </StyledTableCell>
                  <StyledTableCell align="left" className="plantstablewidth">
                    Geschmack
                  </StyledTableCell>
                  {!isScreenSmall && (
                    <>
                      <SortableTableHeaderCell
                        label="Blütedauer"
                        field="BloomDuration"
                        sortConfig={sortConfig}
                        handleSort={handleSort}
                      />
                      <StyledTableCell align="left">
                        Terpen-Profil
                      </StyledTableCell>
                      <StyledTableCell align="left">Extras</StyledTableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(filteredPlants)
                  .filter(([_, details]) => details.Status !== "inaktive") // Filtert "inaktive" Einträge heraus
                  .map(([plantname, details]) => (
                    <StyledTableRow key={plantname}>
                      <StyledTableCell align="center">
                        <img
                          src={`../../images/BudPictures/${details.plantname.replace(
                            /\s+/g,
                            "-"
                          )}.png`}
                          alt={details.plantname}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            minWidth: "50px",
                            minHeight: "50px",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {details.plantname}
                      </StyledTableCell>
                      <StyledTableCell>{details.THC} %</StyledTableCell>
                      <StyledTableCell>{details.CBD}</StyledTableCell>
                      <StyledTableCell>{details.type}</StyledTableCell>
                      <StyledTableCell>
                        {details.Effect && details.Effect.join(", ")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {details.Taste && details.Taste.join(", ")}
                      </StyledTableCell>
                      {!isScreenSmall && (
                        <>
                          <StyledTableCell>
                            {details.BloomDuration}
                          </StyledTableCell>
                          <StyledTableCell>
                            {details["Terpen-Profil"]}
                          </StyledTableCell>
                          <StyledTableCell>{details.Extras}</StyledTableCell>
                        </>
                      )}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </HelmetProvider>
    </div>
  );
};

export default PlantsData3;

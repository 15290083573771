import * as React from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import supabase from "../../../config/supabaseClient.js";
import { useState, useEffect } from "react";

export default function EditMemberMessageDialog() {
  const [latestMessage, setLatestMessage] = useState("");
  const [editedMessage, setEditedMessage] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClickOpen = () => {
    //console.log("Dialog wird geöffnet");
    setDialogOpen(true);
    //console.log(dialogOpen);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const fetchLatestMessage = async () => {
      try {
        const { data, error } = await supabase
          .from("misc")
          .select("*")
          .eq("keyword", "News") // Filtere nach dem Keyword "News"
          .order("created_at", { ascending: false })
          .limit(1);

        if (error) {
          console.error("Fehler beim Abrufen des letzten Eintrags:", error);
          return;
        }

        if (data && data.length > 0) {
          setLatestMessage(data[0].content?.message || "");
          setEditedMessage(data[0].content?.message || "");
        } else {
          setLatestMessage("Es gibt keine Nachrichten.");
        }
      } catch (error) {
        console.error("Allgemeiner Fehler:", error);
      }
    };

    fetchLatestMessage();
  }, []);

  const handleEditMessage = async () => {
    try {
      const { data: latestData, error: latestError } = await supabase
        .from("misc")
        .select("*")
        .eq("keyword", "News")
        .order("created_at", { ascending: false })
        .limit(1);
      console.log(latestData);
      if (latestError) {
        console.error(
          "Fehler beim Abrufen des neuesten Eintrags:",
          latestError
        );
        return;
      }

      if (latestData && latestData.length > 0) {
        const latestEntryId = latestData[0].id;

        const { data: updatedData, error: updateError } = await supabase
          .from("misc")
          .update({ content: { message: editedMessage } })
          .eq("id", latestEntryId);
        console.log(latestEntryId);
        if (updateError) {
          console.error(
            "Fehler beim Aktualisieren der Nachricht:",
            updateError
          );
          return;
        }

        console.log("Nachricht erfolgreich aktualisiert:", updatedData);
      } else {
        console.error("Keine Daten gefunden, um die Nachricht zu bearbeiten.");
      }
    } catch (error) {
      console.error("Allgemeiner Fehler:", error);
    }

    handleCloseDialog();
    //window.location.reload(false);
  };

  const handleDeleteMessage = async () => {
    const confirmDelete = window.confirm(
      "Möchten Sie die Nachricht endgültig löschen?"
    );

    if (confirmDelete) {
      try {
        const { data, error } = await supabase
          .from("misc")
          .select("id")
          .eq("keyword", "News")
          .order("created_at", { ascending: false })
          .limit(1);

        if (error) {
          console.error("Fehler beim Abrufen des letzten Eintrags:", error);
          return;
        }

        if (data && data.length > 0) {
          const latestEntryId = data[0].id;
          console.log(latestEntryId);
          const { error: deleteError } = await supabase
            .from("misc")
            .delete()
            .eq("id", latestEntryId);

          if (deleteError) {
            console.error("Fehler beim Löschen der Nachricht:", deleteError);
            return;
          }

          console.log("Nachricht erfolgreich gelöscht");
          setLatestMessage("");
        } else {
          console.error("Keine Daten gefunden, um die Nachricht zu löschen.");
        }
      } catch (error) {
        console.error("Allgemeiner Fehler:", error);
      }
    }
    console.log(confirmDelete);
    handleCloseDialog();
    window.location.reload(false);
  };

  return (
    <React.Fragment>
      <Button
        className="button"
        variant="contained"
        color="success"
        onClick={handleClickOpen}
        sx={{
          "@media screen and (max-width: 600px)": {
            // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
            fontSize: 10,
            // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
          },
        }}
      >
        letzte Mitteilung bearbeiten
      </Button>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle> Vereinsmitteilung:</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              multiline
              rows={3}
              value={editedMessage}
              onChange={(e) => {
                setEditedMessage(e.target.value);
                /* console.log("Message State:", message) */
              }}
              style={{ width: "400px" }}
            ></TextField>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Schließen</Button>
          <Button onClick={handleEditMessage}>Ändern</Button>
          <Button onClick={handleDeleteMessage}>Löschen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import React from "react";
import "./Roadmap.css"; // Stil-Datei für die Roadmap

const Roadmap = () => {
  const steps = [
    "Planung",
    "Gründung",
    "Eintragung",
    "Lizenzantrag",
    "Lizenzerlangung",
    "Vereinsheim Restauration",
    "Ferigstellung Vereinsheim",
    "Anbaubeginn",
    "Bezug Produktionshalle"
  ];

  const stepDates = [
    "04.01.2022",
    "13.04.2024",
    "30.05.2024",
    "01.07.2024",
    "18.07.2024",
    "05.08.2024",
    "14.12.2024",
    "XX.12.2024",
    "XX.XX.2025"
  ];

  const currentStep = "Vereinsheim Restauration"; // Vergleiche als String

  const totalSteps = steps.length;
  const currentStepIndex = steps.findIndex((step) => step === currentStep);
  const progress = (currentStepIndex / (totalSteps - 1)) * 100;

  return (
    <div className="roadmap-container">
      <div className="roadmap-progress" style={{ width: `${progress}%` }}></div>
      <div className="roadmap-steps">
        {steps.map((step, index) => (
          <div key={index} className="step-container">
            <div
              className={`step ${index <= currentStepIndex ? "completed" : ""}`}
            >
              {/* Bedingung für Container Restauration */}
              {step === "Vereinsheim Restauration" ? (
                <span>Vereinsheim<br />Restauration</span>
              ) : step === "Ferigstellung Vereinsheim" ? (
                <span>Ferigstellung<br />Vereinsheim</span>
              ) : step === "Bezug Produktionshalle" ? (
                <span>Bezug<br />Produktionshalle</span>
              ) : (
                step
              )}
            </div>
            <div className="date">{stepDates[index]}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;

//Basic
import { React, useEffect, useState } from "react";
import "../../../App.css";
import supabase from "../../../config/supabaseClient";
import { Fragment } from "react";

//comps
import ChangeRoleDialog from "./ChangeRoleDialog";
import ChangeModelDialog from "./ChangeModelDialog";

//MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--primary)",
    color: theme.palette.common.black,
    border: "1px solid black",
    "@media screen and (maxwidth: 750px)": {
      // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
      fontSize: 10, // Beispiel: Verkleinern Sie die Schriftgröße
      padding: "6px",
      minWidth: "15px",
      maxWidth: "120px",
      // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    "@media screen and (maxwidth: 750px)": {
      // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
      fontSize: 10, // Beispiel: Verkleinern Sie die Schriftgröße
      padding: "6px",
      minWidth: "15px",
      maxWidth: "120px",
      // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
    },
  },
  // Hinzufügen von Padding für das Eingabefeld
  position: "relative",
  padding: "8px",
  "@media screen and (maxwidth: 750px)": {
    // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
    padding: "6px",
    minWidth: "15px",
    maxWidth: "120px",
    // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: "1px solid black",
  },
}));

const MemberTable = (props) => {
  const { setToken, token, UserData, setUserData, order, ascending } = props;
  const [members, setMembers] = useState(null);
  const [fetchError, setFetchError] = useState(null);

  const [orderBy, setOrderBy] = useState("created_at");


  // Zustände für die Filterwerte
  const [filterNachname, setFilterNachname] = useState("");
  const [filterVorname, setFilterVorname] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterModel, setFilterModel] = useState("");

  const [selectedRole, setSelectedRole] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [clickedColumnIndex, setClickedColumnIndex] = useState(null);

  const [selectedModel, setSelectedModel] = useState(null);
  const [modelDialogOpen, setModelDialogOpen] = useState(false);
  const [clickedModelColumnIndex, setClickedModelColumnIndex] = useState(null);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const { data, error } = await supabase.from("User").select("*");
  
        if (error) {
          setFetchError("Could not fetch the Members");
          setMembers(null);
          console.error(error);
          return;
        }
  
        if (data) {
          const sortedData = data.sort((a, b) => {
            const valueA = a[order] || ""; // Verwende den Wert von `order`
            const valueB = b[order] || "";
            return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
          });
  
          setMembers(sortedData);
          setFetchError(null);
        }
      } catch (error) {
        console.error("Error fetching Members:", error);
        setFetchError("Could not fetch the Members");
      }
    };
  
    fetchMembers();
  }, [order, ascending]);

  // Funktionen zum Aktualisieren der Filterwerte
  const handleFilterNachnameChange = (e) => setFilterNachname(e.target.value);
  const handleFilterVornameChange = (e) => setFilterVorname(e.target.value);
  const handleFilterEmailChange = (e) => setFilterEmail(e.target.value);
  const handleStatusChange = (event) => {
    setFilterStatus(event.target.value);
  };
  const handleModelChange = (event) => {
    setFilterModel(event.target.value);
  };

  const handleRoleClick = (
    event,
    role,
    memberId,
    columnIndex,
    dialogOpen,
    setDialogOpen
  ) => {
    //console.log("handleRoleClick called", role, memberId);
    event.preventDefault(); // Verhindert, dass das Standardverhalten des Klickens auf einen Link oder einer Aktion auf einer Schaltfläche ausgeführt wird
    setSelectedRole({ role, id: memberId });
    setDialogOpen(true);
    //console.log("Dialog Open MemberTable: ", dialogOpen);
    setClickedColumnIndex(columnIndex);
  };

  const handleModelClick = (
    event,
    model,
    memberId,
    columnIndex,
    modelDialogOpen,
    setModelDialogOpen
  ) => {
    event.preventDefault();
    setSelectedModel({ model, id: memberId });
    setModelDialogOpen(true);
    setClickedModelColumnIndex(columnIndex);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        {fetchError && <p>{fetchError}</p>}
        {members && members.length > 0 && (
          <Fragment>
            <TableContainer
              component={Paper}
              sx={{
                width: "100%",
                minWidth: 200,
                maxWidth: 900,
                margin: "auto",
                height: members.length > 20 ? "500px" : "auto", // Setze eine feste Höhe, wenn mehr als 10 Einträge vorhanden sind
                overflowY: "auto", // Füge eine Scrollbar hinzu, wenn die Höhe überschritten wird
                overflowX: "auto",
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* Nachname Spalte */}
                    <StyledTableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          color="success"
                          label="Nachname"
                          value={filterNachname}
                          onChange={handleFilterNachnameChange}
                          autoComplete="off"
                          InputProps={{
                            style: {
                              color: "var(--secondary)",
                            },
                            classes: {
                              notchedOutline: "var(--secondary)", // Ändere "white-border" zu einem passenden Klassenname
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: "var(--secondary)",
                            },
                            classes: {
                              root: "var(--secondary)", // Ändere "white-label" zu einem passenden Klassenname
                            },
                          }}
                          style={{
                            marginLeft: "8px",
                          }}
                        />
                      </div>
                    </StyledTableCell>

                    {/* Vorname Spalte */}
                    <StyledTableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          color="success"
                          label="Vorname"
                          value={filterVorname}
                          onChange={handleFilterVornameChange}
                          autoComplete="off"
                          InputProps={{
                            style: {
                              color: "var(--secondary)",
                            },
                            classes: {
                              notchedOutline: "var(--secondary)", // Ändere "white-border" zu einem passenden Klassenname
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: "var(--secondary)",
                            },
                            classes: {
                              root: "var(--secondary)", // Ändere "white-label" zu einem passenden Klassenname
                            },
                          }}
                          style={{
                            marginLeft: "8px",
                          }}
                        />
                      </div>
                    </StyledTableCell>

                    {/* Email Spalte */}
                    <StyledTableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          color="success"
                          label="E-Mail"
                          value={filterEmail}
                          onChange={handleFilterEmailChange}
                          autoComplete="off"
                          InputProps={{
                            style: {
                              color: "var(--secondary)",
                            },
                            classes: {
                              notchedOutline: "var(--secondary)", // Ändere "white-border" zu einem passenden Klassenname
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: "var(--secondary)",
                            },
                            classes: {
                              root: "var(--secondary)", // Ändere "white-label" zu einem passenden Klassenname
                            },
                          }}
                          style={{
                            marginLeft: "8px",
                          }}
                        />
                      </div>
                    </StyledTableCell>

                    {/* Status Spalte */}
                    <StyledTableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControl
                          style={{ marginLeft: "8px", width: "100px" }}
                        >
                          <InputLabel
                            id="status-filter-label"
                            style={{ color: "var(--secondary)" }}
                          >
                            Status
                          </InputLabel>
                          <Select
                          color="success"
                            labelId="status-filter-label"
                          label="Status"
                            id="status-filter"
                            value={filterStatus}
                            onChange={handleStatusChange}
                            style={{ color: "var(--secondary)" }}
                          >
                            <MenuItem value="">Alle</MenuItem>
                            <MenuItem value={"Admin"}>Admin</MenuItem>
                            <MenuItem value={"Vorstand"}>Vorstand</MenuItem>
                            <MenuItem value={"Helfer"}>Helfer</MenuItem>
                            <MenuItem value={"neuer Helfer"}>
                              neuer Helfer
                            </MenuItem>
                            <MenuItem value={"Prime-Mitglied"}>Prime-Mitglied</MenuItem>
                            <MenuItem value={"Mitglied"}>Mitglied</MenuItem>
                            <MenuItem value={"Registriert"}>
                              Registriert
                            </MenuItem>
                            <MenuItem value={"Bann"}>Bann</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </StyledTableCell>

                    {/* Model Spalte */}
                    <StyledTableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControl
                          style={{ marginLeft: "8px", width: "100px" }}
                        >
                          <InputLabel
                            id="model-filter-label"
                            style={{ color: "var(--secondary)"}}
                            label="Vereinsmodell"
                          >
                            Vereinsmodell
                          </InputLabel>
                          <Select
                          label="Vereinsmodell"
                          color="success"
                            labelId="model-filter-label"
                            id="model-filter"
                            value={filterModel}
                            onChange={handleModelChange}
                            style={{ color: "var(--secondary)" }}
                          >
                            <MenuItem value="">Alle</MenuItem>
                            <MenuItem value={"Vereinspflege"}>
                              Vereinspflege
                            </MenuItem>
                            <MenuItem value={"Privatpflege"}>
                              Privatpflege
                            </MenuItem>
                            <MenuItem value={"Pausiert"}>Pausiert</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    "&:last-child td, &:last-child th": { border: 1 },
                  }}
                >
                  {members
                    .filter((member) =>
                      (member.lastname || "")
                        .toLowerCase()
                        .includes(filterNachname.toLowerCase())
                    )
                    .filter((member) =>
                      (member.firstname || "")
                        .toLowerCase()
                        .includes(filterVorname.toLowerCase())
                    )
                    .filter((member) =>
                      (member.email || "")
                        .toLowerCase()
                        .includes(filterEmail.toLowerCase())
                    )
                    .filter((member) =>
                      (member.role || "")
                        .toLowerCase()
                        .includes(filterStatus.toLowerCase())
                    )
                    .filter((member) =>
                      (member.model || "")
                        .toLowerCase()
                        .includes(filterModel.toLowerCase())
                    )
                    .map((member, columnIndex) => (
                      <StyledTableRow key={member.id}>
                        <StyledTableCell align="right">
                          {member.lastname}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {member.firstname}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {member.email}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          onClick={(event) =>
                            handleRoleClick(
                              event,
                              member.role,
                              member.id,
                              columnIndex,
                              dialogOpen,
                              setDialogOpen
                            )
                          }
                        >
                          {member.role}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          onClick={(event) =>
                            handleModelClick(
                              event,
                              member.model,
                              member.id,
                              columnIndex,
                              modelDialogOpen,
                              setModelDialogOpen
                            )
                          }
                        >
                          {member.model}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <ChangeRoleDialog
              role={selectedRole?.role}
              id={selectedRole?.id}
              memberId={selectedRole?.id}
              columnIndex={clickedColumnIndex}
              members={members} // Hinzugefügter Teil
              setMembers={setMembers}
              open={dialogOpen}
              onClose={() => {
                setDialogOpen(false);
                setSelectedRole(null);
                setClickedColumnIndex(null);
              }}
            />

            {/* Dialog für Vereinsmodell */}
            <ChangeModelDialog
              model={selectedModel?.model}
              id={selectedModel?.id}
              memberId={selectedModel?.id}
              columnIndex={clickedModelColumnIndex}
              members={members}
              setMembers={setMembers}
              open={modelDialogOpen}
              onClose={() => {
                setModelDialogOpen(false);
                setSelectedModel(null);
                setClickedModelColumnIndex(null);
              }}
            />
            {/* <Button onClick={startCronjob}>Cronjob Test</Button> */}
          </Fragment>
        )}
      </div>
    </>
  );
};

export default MemberTable;

import React, { useEffect } from "react";
import { View, Text, Image, StyleSheet } from "react-native";
import { styles } from "../styles";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--primary)",
    color: theme.palette.common,
    border: "1px solid black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  // Hinzufügen von Padding für das Eingabefeld
  position: "relative",
  padding: "8px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: "1px solid black",
  },
}));



const Equipment = () => {
  return (
    <HelmetProvider>
    <div>
    <Helmet>
      <title>Ausrüstung der Anbauvereinigung Hemp Heaven Hannover</title>
      <meta
        name="description"
        content="Informiere dich über die Ausrüstung die in der Anbauvereinigung Hemp Heaven Hannover verwendet wird um die besten Blüten heranzuzüchten."
      />
      <meta
        name="keywords"
        content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
      />
    </Helmet>
    <Box
      component={Paper}
      sx={{
        maxWidth: 1000,
        margin: "auto",
        marginTop: 5,
        padding: 2,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={require(`../images/Equipment.webp`)}
        style={{ maxWidth: "450px", maxHeight: "600px", width: "100%" }}
      />
      <Text style={{ textAlign: "center" }}>
        <span style={styles.titleText}>
          {"\n"} Rückerstattungsfähige Erstausstattung {"\n"}
        </span>
        <span style={styles.normalText}>
          Die Angaben beziehen sich auf ein gesamtes Zelt
        </span>
      </Text>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          margin: "auto",
          marginTop: 5,
          overflowY: "auto", // Füge eine Scrollbar hinzu, wenn die Höhe überschritten wird
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Bild</StyledTableCell>
              <StyledTableCell align="left">Beschreibung</StyledTableCell>
              <StyledTableCell align="left">Preis</StyledTableCell>
              <StyledTableCell align="left">
                Kosten pro Mitglied (3 Pflanzen)
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ "&:last-child td, &:last-child th": { border: 1 } }}>
            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Zelt.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Zelt"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                Zelsius Gewächszelt 120 x 120 x 200 cm
              </StyledTableCell>
              <StyledTableCell align="center">79,95 €</StyledTableCell>
              <StyledTableCell align="center">26,65 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/LED_Lumii-black-720W.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="LED_Lumii-black-720W"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                LUMii Black LED-Leuchte 720 W Set mit LUMii Black Vorschaltgerät
              </StyledTableCell>
              <StyledTableCell align="center"> 529,90 €</StyledTableCell>
              <StyledTableCell align="center"> 176,63 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Seilzug.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Seilzug"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                Garden Highpro Pro Hanger 2er Set 68kg
              </StyledTableCell>
              <StyledTableCell align="center"> 8,50 €</StyledTableCell>
              <StyledTableCell align="center"> 2,83 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Luefter.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Lüfter"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                Prima Klima Fan 820m³/ h Temp. Controlled - 160mm
              </StyledTableCell>
              <StyledTableCell align="center"> 184,00 €</StyledTableCell>
              <StyledTableCell align="center"> 61,33 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Abluftschlauch.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Abluftschlauch"
                />
              </StyledTableCell>
              <StyledTableCell align="center">Abluftschlauch</StyledTableCell>
              <StyledTableCell align="center"> 7,10 €</StyledTableCell>
              <StyledTableCell align="center"> 2,36 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Spanngurt.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Spanngurt"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                Taifun Spanngurt 4er Set
              </StyledTableCell>
              <StyledTableCell align="center"> 4,90 €</StyledTableCell>
              <StyledTableCell align="center"> 1,63 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Umluftventi.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Umluftventi"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                {" "}
                2x Taifun Clip-Ventilator Ø15 cm, 15 Watt
              </StyledTableCell>
              <StyledTableCell align="center"> 39,80 €</StyledTableCell>
              <StyledTableCell align="center"> 13,27 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Blumatkegel.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Blumatkegel"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                Tropf-Blumat Packung (9 Stück), mit T-Stück, Tropfschlauch und
                Zufuhrschlauch
              </StyledTableCell>
              <StyledTableCell align="center"> 45,81 €</StyledTableCell>
              <StyledTableCell align="center"> 15,27 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Mehrfachsteckdose.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Mehrfachsteckdose"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                1x Mehrfachsteckdose
              </StyledTableCell>
              <StyledTableCell align="center"> 9,00 €</StyledTableCell>
              <StyledTableCell align="center"> 3,00 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Zeitschaltuhr.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Zeitschaltuhr"
                />
              </StyledTableCell>
              <StyledTableCell align="center">1x Zeitschaltuhr</StyledTableCell>
              <StyledTableCell align="center"> 4,99 €</StyledTableCell>
              <StyledTableCell align="center"> 1,66 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/gronest-stofftopf_5.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Stofftopf"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                {" "}
                9x Gronest 11 Liter Stofftöpfe
              </StyledTableCell>
              <StyledTableCell align="center"> 32,99 €</StyledTableCell>
              <StyledTableCell align="center"> 10,99 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/ESP32.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="ESP32"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                1x ESP32 Steuerungseinheit
              </StyledTableCell>
              <StyledTableCell align="center"> 10,49 €</StyledTableCell>
              <StyledTableCell align="center"> 3,50 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/DHT22.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="DHT22"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                1x DHT22 Temperatur und Luftfeuchtigkeitssensor
              </StyledTableCell>
              <StyledTableCell align="center"> 6,40 €</StyledTableCell>
              <StyledTableCell align="center"> 2,13 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/USB-Kabel.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="USB-Kabel"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                1x Netzteil mit USB-Kabel
              </StyledTableCell>
              <StyledTableCell align="center"> 9,99 €</StyledTableCell>
              <StyledTableCell align="center"> 3,33 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Transportgefaeß.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Transportgefäß"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                9x Transport- Veredelungsgefäße
              </StyledTableCell>
              <StyledTableCell align="center"> 57,60 €</StyledTableCell>
              <StyledTableCell align="center"> 19,20 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">Summe</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center"> 1031,42 €</StyledTableCell>
              <StyledTableCell align="center"> 343,81 €</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Text style={{ textAlign: "center" }}>
        <span style={styles.titleText}>
          {"\n"} nicht Rückerstattungsfähige Erstausstattung {"\n"}
        </span>
        <span style={styles.normalText}>
          Die Angaben beziehen sich auf ein gesamtes Zelt
        </span>
      </Text>

      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          //minWidth: 300,
          //maxWidth: 1200,
          margin: "auto",
          marginTop: 5,
          //height: members.length > 20 ? "500px" : "auto", // Setze eine feste Höhe, wenn mehr als 10 Einträge vorhanden sind
          overflowY: "auto", // Füge eine Scrollbar hinzu, wenn die Höhe überschritten wird
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Bild</StyledTableCell>
              <StyledTableCell align="left">Beschreibung</StyledTableCell>
              <StyledTableCell align="left">Preis</StyledTableCell>
              <StyledTableCell align="left">
                Kosten pro Mitglied (3 Pflanzen)
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ "&:last-child td, &:last-child th": { border: 1 } }}>
            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Aktivkohlefilter.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Aktivkohlefilter"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                Can Lite Filter 800m³/h Ø160mm Stahlkorpus
              </StyledTableCell>
              <StyledTableCell align="center"> 119,90 €</StyledTableCell>
              <StyledTableCell align="center"> 39,96 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/BioBizz_lightmix.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="BioBizz_lightmix"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                60L BioBizz Pflanzenerde
              </StyledTableCell>
              <StyledTableCell align="center"> 20,26 €</StyledTableCell>
              <StyledTableCell align="center"> 6,75 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/BioBizz_cocomix.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="BioBizz_cocomix"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                30L BioBizz Cocos Substrat
              </StyledTableCell>
              <StyledTableCell align="center"> 10,13 €</StyledTableCell>
              <StyledTableCell align="center"> 3,38 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Florganics_Duenger.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Florganics_Dünger"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                9L Florganics organischer Pflanzendünger
              </StyledTableCell>
              <StyledTableCell align="center"> 77,00 €</StyledTableCell>
              <StyledTableCell align="center"> 25,67 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Jiffys.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Jiffys"
                />
              </StyledTableCell>
              <StyledTableCell align="center"> 9x Jiffys</StyledTableCell>
              <StyledTableCell align="center"> 1,80 €</StyledTableCell>
              <StyledTableCell align="center"> 0,60 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Hygro-pack.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Hygro-pack"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                9x 67g Hygro-Pack
              </StyledTableCell>
              <StyledTableCell align="center"> 22,50 €</StyledTableCell>
              <StyledTableCell align="center"> 7,50 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Samen.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Samen"
                />
              </StyledTableCell>
              <StyledTableCell align="center"> 9x Samen</StyledTableCell>
              <StyledTableCell align="center"> 90,00 €</StyledTableCell>
              <StyledTableCell align="center"> 30,00 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Gelbtafeln.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Gelbtafeln"
                />
              </StyledTableCell>
              <StyledTableCell align="center"> 9x Gelbtafeln</StyledTableCell>
              <StyledTableCell align="center">1,05 €</StyledTableCell>
              <StyledTableCell align="center"> 0,35 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">Summe</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center"> 342,64 €</StyledTableCell>
              <StyledTableCell align="center"> 114,21 €</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Text style={styles.titleText}>
        {"\n"} sonstige notwendige Anschaffungen
      </Text>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          //minWidth: 300,
          //maxWidth: 1200,
          margin: "auto",
          marginTop: 5,
          //height: members.length > 20 ? "500px" : "auto", // Setze eine feste Höhe, wenn mehr als 10 Einträge vorhanden sind
          overflowY: "auto", // Füge eine Scrollbar hinzu, wenn die Höhe überschritten wird
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Bild</StyledTableCell>
              <StyledTableCell align="left">Beschreibung</StyledTableCell>
              <StyledTableCell align="left">Preis</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ "&:last-child td, &:last-child th": { border: 1 } }}>
            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Spruehflasche.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Sprühflasche"
                />
              </StyledTableCell>
              <StyledTableCell align="center">Sprühflasche</StyledTableCell>
              <StyledTableCell align="center"> 10,90 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Schnittschere.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Schnittschere"
                />
              </StyledTableCell>
              <StyledTableCell align="center">Schnittschere</StyledTableCell>
              <StyledTableCell align="center"> 8,50 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Baumschere.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Baumschere"
                />
              </StyledTableCell>
              <StyledTableCell align="center">Baumschere</StyledTableCell>
              <StyledTableCell align="center"> 9,99 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/LED-Schutzbrille.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="LED-Schutzbrille"
                />
              </StyledTableCell>
              <StyledTableCell align="center">LED-Schutzbrille</StyledTableCell>
              <StyledTableCell align="center"> 19,90 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Handschuhe.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Handschuhe"
                />
              </StyledTableCell>
              <StyledTableCell align="center"> Handschuhe</StyledTableCell>
              <StyledTableCell align="center"> 14,59 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Verlaengerungskabel.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Verlängerungskabel"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                Verlängerungskabel
              </StyledTableCell>
              <StyledTableCell align="center"> 11,99 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Anzuchtkasten.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Anzuchtkasten"
                />
              </StyledTableCell>
              <StyledTableCell align="center"> Anzuchtkasten</StyledTableCell>
              <StyledTableCell align="center"> 28,90 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Heizmatte.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Heizmatte"
                />
              </StyledTableCell>
              <StyledTableCell align="center"> Heizmatte</StyledTableCell>
              <StyledTableCell align="center"> 44,90 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Desinfektionsmittel.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Desinfektionsmittel"
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                Desinfektionsmittel
              </StyledTableCell>
              <StyledTableCell align="center"> 19,99 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Telefonmikroskop.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Telefonmikroskop"
                />
              </StyledTableCell>
              <StyledTableCell align="center">Telefonmikroskop</StyledTableCell>
              <StyledTableCell align="center"> 38,98 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Schutzanzug.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Schutzanzug"
                />
              </StyledTableCell>
              <StyledTableCell align="center">10x Schutzanzüge</StyledTableCell>
              <StyledTableCell align="center"> 36,99 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Mischbehaelter.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Mischbehälter"
                />
              </StyledTableCell>
              <StyledTableCell align="center">Mischbehälter</StyledTableCell>
              <StyledTableCell align="center"> 51,00 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Waage.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Waage"
                />
              </StyledTableCell>
              <StyledTableCell align="center">Waage</StyledTableCell>
              <StyledTableCell align="center"> 19,99 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Heizstrahler.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Heizstrahler"
                />
              </StyledTableCell>
              <StyledTableCell align="center"> 2x Heizstrahler</StyledTableCell>
              <StyledTableCell align="center"> 259,36 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/IBC-Container.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="IBC-Container"
                />
              </StyledTableCell>
              <StyledTableCell align="center"> 2x IBC-Container</StyledTableCell>
              <StyledTableCell align="center"> 399,98 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/ph-messgeraet.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="pH-Messgerät"
                />
              </StyledTableCell>
              <StyledTableCell align="center">pH-Messgerät</StyledTableCell>
              <StyledTableCell align="center"> 109,90 €</StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <img
                  src={require(`../images/Equipment/Dampfreiniger.webp`)}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "200px",
                    width: "100%",
                    minWidth: "50px",
                  }}
                  alt="Dampfreiniger"
                />
              </StyledTableCell>
              <StyledTableCell align="center">Dampfreiniger</StyledTableCell>
              <StyledTableCell align="center"> 29,99 €</StyledTableCell>
            </TableRow>

<TableRow>
  <StyledTableCell align="center">
    <img
      src={require(`../images/Equipment/Feinschnittschere.webp`)}
      style={{
        maxWidth: "150px",
        maxHeight: "200px",
        width: "100%",
        minWidth: "50px",
      }}
      alt="Feinschnittschere"
    />
  </StyledTableCell>
  <StyledTableCell align="center">Feinschnittschere</StyledTableCell>
  <StyledTableCell align="center"> 4,90 €</StyledTableCell>
</TableRow>

<TableRow>
  <StyledTableCell align="center">
    <img
      src={require(`../images/Equipment/Wickelfalzrohr.webp`)}
      style={{
        maxWidth: "150px",
        maxHeight: "200px",
        width: "100%",
        minWidth: "50px",
      }}
      alt="Wickelfalzrohr"
    />
  </StyledTableCell>
  <StyledTableCell align="center">20x Wickelfalzrohr</StyledTableCell>
  <StyledTableCell align="center"> 456,00 €</StyledTableCell>
</TableRow>

<TableRow>
  <StyledTableCell align="center">
    <img
      src={require(`../images/Equipment/Wickelfalz-Verbinder.webp`)}
      style={{
        maxWidth: "150px",
        maxHeight: "200px",
        width: "100%",
        minWidth: "50px",
      }}
      alt="Wickelfalz Verbinder"
    />
  </StyledTableCell>
  <StyledTableCell align="center">20x Wickelfalz Verbinder</StyledTableCell>
  <StyledTableCell align="center"> 177,80 €</StyledTableCell>
</TableRow>

<TableRow>
  <StyledTableCell align="center">
    <img
      src={require(`../images/Equipment/Wickelfalz-90°-Bogen.webp`)}
      style={{
        maxWidth: "150px",
        maxHeight: "200px",
        width: "100%",
        minWidth: "50px",
      }}
      alt="Wickelfalz 90° Bogen"
    />
  </StyledTableCell>
  <StyledTableCell align="center"> 14x Wickelfalz 90° Bogen</StyledTableCell>
  <StyledTableCell align="center"> 450,52 €</StyledTableCell>
</TableRow>

<TableRow>
  <StyledTableCell align="center">
    <img
      src={require(`../images/Equipment/Lueftungsschellen.webp`)}
      style={{
        maxWidth: "150px",
        maxHeight: "200px",
        width: "100%",
        minWidth: "50px",
      }}
      alt="Lüftungsschellen"
    />
  </StyledTableCell>
  <StyledTableCell align="center"> 60x Lüftungsschellen</StyledTableCell>
  <StyledTableCell align="center"> 379,20 €</StyledTableCell>
</TableRow>

<TableRow>
  <StyledTableCell align="center">
    <img
      src={require(`../images/Equipment/Wickelfalz-45°-Ableiter.webp`)}
      style={{
        maxWidth: "150px",
        maxHeight: "200px",
        width: "100%",
        minWidth: "50px",
      }}
      alt="Wickelfalz 45° Ableiter"
    />
  </StyledTableCell>
  <StyledTableCell align="center"> 40x Wickelfalz 45° Ableiter</StyledTableCell>
  <StyledTableCell align="center"> 1624,80 €</StyledTableCell>
</TableRow>

<TableRow>
  <StyledTableCell align="center">
    <img
      src={require(`../images/Equipment/Wickelfalz-Enddeckel.webp`)}
      style={{
        maxWidth: "150px",
        maxHeight: "200px",
        width: "100%",
        minWidth: "50px",
      }}
      alt="Wickelfalz Enddeckel"
    />
  </StyledTableCell>
  <StyledTableCell align="center"> 4x Wickelfalz Enddeckel</StyledTableCell>
  <StyledTableCell align="center"> 48,28 €</StyledTableCell>
</TableRow>

<TableRow>
  <StyledTableCell align="center">
    <img
      src={require(`../images/Equipment/Dachdurchführung.webp`)}
      style={{
        maxWidth: "150px",
        maxHeight: "200px",
        width: "100%",
        minWidth: "50px",
      }}
      alt="Dachdurchführung"
    />
  </StyledTableCell>
  <StyledTableCell align="center"> 2x Dachdurchführung</StyledTableCell>
  <StyledTableCell align="center"> 325,70 €</StyledTableCell>
</TableRow>

<TableRow>
  <StyledTableCell align="center">
    <img
      src={require(`../images/Equipment/Regenkragen.webp`)}
      style={{
        maxWidth: "150px",
        maxHeight: "200px",
        width: "100%",
        minWidth: "50px",
      }}
      alt="Regenkragen"
    />
  </StyledTableCell>
  <StyledTableCell align="center"> 4x Regenkragen</StyledTableCell>
  <StyledTableCell align="center"> 209,36 €</StyledTableCell>
</TableRow>

<TableRow>
  <StyledTableCell align="center">
    <img
      src={require(`../images/Equipment/Dachhaube.webp`)}
      style={{
        maxWidth: "150px",
        maxHeight: "200px",
        width: "100%",
        minWidth: "50px",
      }}
      alt="Dachhaube"
    />
  </StyledTableCell>
  <StyledTableCell align="center"> 2x Dachhaube</StyledTableCell>
  <StyledTableCell align="center"> 158,92 €</StyledTableCell>
</TableRow>

<TableRow>
  <StyledTableCell align="center">
    <img
      src={require(`../images/Equipment/Container.webp`)}
      style={{
        maxWidth: "150px",
        maxHeight: "200px",
        width: "100%",
        minWidth: "50px",
      }}
      alt="Container"
    />
  </StyledTableCell>
  <StyledTableCell align="center"> Container Restauration und Renovierung</StyledTableCell>
  <StyledTableCell align="center"> ~8000 €</StyledTableCell>
</TableRow>

            <TableRow>
              <StyledTableCell align="center">Summe</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center"> 12995,33 €</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    </div>
</HelmetProvider>
  );
};

export default Equipment;
